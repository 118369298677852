/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
    var Sage = {
// All pages
        'common': {
            init: function () {
// JavaScript to be fired on all pages

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                $('ul.sf-menu-ul').superfish();

                //Open search box on click of search icon from header
                $('#header-search').hide();
                $('.search-icon,.close-search').on('click', function () {
                    $('#header-search').fadeToggle();
                });
                //Parallax effect of header image
                jQuery(window).scroll(function (e) {
                    parallax();
                });
                function parallax() {
                    var scrolled = jQuery(window).scrollTop();
                    jQuery('.banner-default').css('top', -(scrolled * 0.4) + 'px');
                }

                //Shop detail page - Make min height of left content as right sidebar
                function shop_left_content_height() {
                    var rHeight = $('.shops_right .shops_right_wrapper').height();
                    $('.shops_left .shops_left_wrapper').css('min-height', rHeight);
                }
                shop_left_content_height();
                $(window).resize(function () {
                    shop_left_content_height();
                });
                //Fade in the Header title
                $(".parallax-image-content").animate({
                    opacity: 1
                }, 1000);

                //Fade content on scroll function
                jQuery.fn.fadeInScroll = function (options) {
                    // VARIABLES ------------------------------------
                    var elements = jQuery(this);
                    // SETTINGS ------------------------------------
                    var settings = jQuery.extend({
                        minDistance: 85 * jQuery(window).height() / 100   //Distance between the browser top scroll and the next element
                    }, options);
                    // MAIN ------------------------------------
                    // Add sFade classes
                    jQuery(elements).each(function () {
                        jQuery(this).css('opacity', '0');
                    });
                    // Check the position of all the elemnents.
                    CheckFades();
                    // FUNCTIONS ------------------------------------
                    function CheckFades() {
                        //Get the top of the browser
                        vWindowScrollTop = jQuery(window).scrollTop();

                        //Test if the window TopScroll reachs the element TopScroll minus the minimun distance
                        jQuery(elements).each(function () {
                            if (((vWindowScrollTop + parseInt(settings.minDistance)) >= jQuery(this).offset().top)) {
                                jQuery(this).animate({opacity: 1}, 500);
                            }
                            if (((vWindowScrollTop + parseInt(140 * jQuery(window).height() / 100)) >= jQuery(this).offset().top)) {
                                var attr = jQuery(this).attr('data-img-attr');
                                if (typeof attr !== typeof undefined && attr !== false) {
                                    jQuery(this).css('background-image', 'url(' + attr + ')');
                                }
                            }
                        });
                    }
                    // EVENTS ------------------------------------
                    jQuery(window).scroll(function () {
                        CheckFades();
                    });
                };
                //Fade sections on scroll
                jQuery('.fadeInBlock').fadeInScroll();
                //Load our shop section on window load.
                jQuery(window).on('load', function (e) {
                    if (jQuery("#shop-content").length > 0) {
                        var current_post_id = js_custom_object.current_postid;
                        $.ajax({
                            data: {action: 'get_our_shop_widgets', 'current_post_id': current_post_id},
                            type: 'POST',
                            url: ajaxurl,
                            beforeSend: function () {
                            },
                            success: function (data) {
                                jQuery('#shop-content').html(data);
                                setTimeout(function(){
                                    jQuery('.loader').hide();
                                    jQuery('.shop-time-status').fadeIn(700);
                                },10);                                
                            }
                        });
                    }
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.